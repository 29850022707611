export function isFeatureEnabled (featureCode, store) {
  if (store?.state?.features?.featuresList) {
    return store?.state?.features?.featuresList[featureCode].isEnabled
  }

  return false
}

export function isFeatureAvailableInPlan (featureCode, store) {
  return store.getters['features/isFeatureAvailableInPlan'](featureCode)
}

export default {
  data: function () {
    return {
      featuresConfigureList: {
        coupons: { routeName: { name: 'coupons' }  },
        custom_fields: { routeName: { name: 'customize-custom-fields', params: { type: 'customer' } } },
        custom_domain: { routeName: { name: 'configure-domain' } },
        custom_notifications: { routeName: { name: 'settings-email-notifications-templates' } },
        custom_roles: { routeName: { name: 'settings-roles' } },
        google_analytics: { routeName: { name: 'google-analytics-integration' } },
        google_tag_manager: { routeName: { name: 'google-tag-manager-integration' } },
        google_calendar: { routeName: { name: 'google-calendar-integration' } },
        group_booking: { routeName: { name: 'group-booking' } },
        online_payments: { routeName: { name: 'settings-payments' } },
        outlook_calendar: { routeName: { name: 'outlook-calendar-integration' } },
        zapier: { routeName: { name: 'zapier-integrations' } },
        zoom_integration: { routeName: { name: 'zoom-integration' } },
        smtp_email: { routeName: { name: 'configure-smtp' } },
        mailchimp_integration: { routeName: { name: 'mailchimp-integration' } },
        sendfox_integration: { routeName: { name: 'sendfox-integration' } },
        facebook_pixel: { routeName: { name: 'facebook-pixel-integration' } },
        webhooks: { routeName: { name: 'configure-webhooks' } },
        whatsapp_integration: { routeName: { name: 'configure-whatsapp' } },
        apple_calendar: { routeName: { name: 'configure-apple-calendar' } },
        reserve_with_google: { routeName: { name: 'reserve-with-google' } },
        multilingual_support: { routeName: { name: 'customize-languages' } },
        booking_chat_bot: { routeName: { name: 'customize-chat-bot' } },
        acumba: { routeName: { name: 'acumba-integration' } },
        rating_review: { routeName: { name: 'rating-review' } },
        resources: { routeName: { name: 'services', params: { type: 'resources' } } },
        api_integration: { routeName: { name: 'api-integration' } },
      }
    }
  },

  methods: {
    getConfigureRouteName(featureCode) {
      return this.featuresConfigureList?.[featureCode]?.routeName
    },

    async saveDeactivateFeature (featureCode) {
      const response = await this.$http.post(
        '/api/v1/settings/features/deactivate',
        { featureCode, itemsToDeactivate: this.itemsToDeactivate },
        { signal: this.cancelToken.signal }
      )

      if (response.status !== 200) {
        this.$message({ message: this.$t('something_went_wrong'), type: 'error', showClose: true })
      }

      if (this.$store.state.billing.deactivateFeatureDialog === true) {
        this.$store.commit('billing/setDeactivateFeatureDialog', false)
        this.$store.commit('billing/setFeatureDeactivated', true)
        this.$store.commit('billing/setBillingDialogStep', 'plans')
      } else {
        this.refreshFeaturesList(featureCode)
      }
    },

    refreshFeaturesList (featureCode) {
      this.updateActiveFeatures()
      const feature = this.features.find(feature => feature.code === featureCode)
      if (feature.isFree === false) {
        --this.activeFeaturesCount
      }
      feature.isEnabled = 0
      this.$message({
        message: this.$t('has_been_deactivated_feature'),
        type: 'success',
        showClose: true
      })
    },

    async getFeatureLowestAvailablePlan (featureCode) {
      let userPlans = []

      await this.$http.get('/api/v1/backoffice/plans').then((response) => {
        userPlans = response.data.plans
      })

      const starter = userPlans.filter(plan => plan.name === 'starter_v2' && plan.billingInterval === 'yearly')[0]
      const scaling = userPlans.filter(plan => plan.name === 'scaling_v2' && plan.billingInterval === 'yearly')[0]
      const isAvailableInStarter = this.$store.getters['features/isFeatureAvailableInPlan'](featureCode, starter)
      const isAvailableInScaling = this.$store.getters['features/isFeatureAvailableInPlan'](featureCode, scaling)

      if (isAvailableInStarter) {
        return 'starter'
      }

      if (isAvailableInScaling) {
        return 'scaling'
      }

      return 'free'
    }
  }
}
