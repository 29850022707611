<template>

  <!-- Dashboard -->
  <div
    :style="cssVars"
    :class="{'is-main-navigation-bar-expanded': $store.state.menu.isExpanded}"
    class="adm-dashboard"
    data-app
  >
    <!-- Dashboard Alerts -->
    <dashboard-alerts />
    <!-- /Dashboard Alerts -->

    <!-- Limit Reached Dialog -->
    <adm-limit-reached-dialog />
    <!-- /Limit Reached Dialog -->

    <!-- Manage Appointment Dialog -->
    <manage-appointment-dialog
      v-if="$store.getters['manageAppointment/isManageDialogVisible']()"
    />
    <!-- /Manage Appointment Dialog -->

    <!-- Navigation Menu -->
    <main-navigation-bar v-if="$screen.aboveTablet" />
    <main-navigation-bar-mobile v-else />
    <!-- /Navigation Menu -->

    <!-- First Step Guide Modal -->
    <guide-modal />
    <!-- /First Step Guide Modal -->

    <!-- Billing Dialog -->
    <billing-dialog />
    <!-- /Billing Dialog -->

    <!-- Share Booking Form Dialog -->
    <share-booking-form-dialog v-if="$store.state.shareBookingForm.visible" />
    <!-- /Share Booking Form Dialog -->

    <!-- App Announcement Banner -->
    <app-announcement-banner
      v-if="!isPwa"
      class="adm-dashboard__app-banner"
    />
    <!-- App Announcement Banner -->

    <!-- Container -->
    <div class="adm-dashboard__container">

      <!-- Header -->
      <main-header />
      <!-- /Header -->

      <!-- <promotion2024 v-if="isPromoBannerVisible" class="adm-dashboard__promo-2024-banner" /> -->

      <!-- Black Friday Promotion -->
      <!--      <black-friday-promotion-banner v-if="isPromoBannerVisible" class="adm-dashboard__bc-promotion-banner" />-->
      <!-- /Black Friday Promotion -->

      <!-- Wrapper -->
      <div
        class="adm-dashboard__container__wrapper"
        :class="{'adm-dashboard__container__wrapper__promo-2024-banner-wrapper': isPromoBannerVisible,
                 'adm-dashboard__container__wrapper__bc-promo-banner-visible': isPromoBannerVisible}"
      >
        <!-- Agency demo alert -->
        <agency-demo-alert v-if="isAgencyDemo" />
        <!-- /Agency demo alert -->

        <!-- Router View -->
        <router-view :key="$store.state.view.routerViewKey" />
        <!-- /Router View -->
      </div>
      <!-- /Wrapper -->

    </div>
    <!-- /Container -->

    <!-- First Step Guide Success Dialog -->
    <guide-dialog-success
      :is-opened="isOpenedSuccessGuide"
    />
    <!-- /First Step Guide Success Dialog -->

  </div>
  <!-- /Dashboard -->

</template>

<script>
import AdmLimitReachedDialog from '@/views/Dashboard/LimitReachedDialog/AdmLimitReachedDialog'
import BillingDialog from '@/views/Dashboard/BillingDialog/BillingDialog'
import GuideDialogSuccess from '@/views/Guide/GuideDialogSuccess'
import GuideModal from '@/views/Guide/GuideModal'
import MainHeader from '@/views/Dashboard/MainHeader/MainHeader'
import MainNavigationBar from '@/views/Dashboard/MainNavigationBar/MainNavigationBar'
import MainNavigationBarMobile from '@/views/Dashboard/MainNavigationBar/MainNavigationBarMobile'
import store from '@/store'
import { mapGetters, mapState } from 'vuex'
import ShareBookingFormDialog from './ShareBookingFormDialog/ShareBookingFormDialog'
import AppAnnouncementBanner from '@/views/Dashboard/AppAnnouncementBanner/AppAnnouncementBanner.vue'
import { isPwa } from '@/utils/isPwa'
import DashboardAlerts from '@/views/Dashboard/_components/DashboardAlerts.vue'
import ManageAppointmentDialog from '@/views/Dashboard/ManageAppointmentDialog/ManageAppointmentDialog.vue'
import AgencyDemoAlert from '@/views/Dashboard/AgencyDemoAlert/AgencyDemoAlert.vue'
import Gleap from 'gleap'

export default {
  name: 'Dashboard',

  components: {
    AgencyDemoAlert,
    DashboardAlerts,
    ManageAppointmentDialog,
    AppAnnouncementBanner,
    ShareBookingFormDialog,
    AdmLimitReachedDialog,
    BillingDialog,
    GuideDialogSuccess,
    GuideModal,
    MainHeader,
    MainNavigationBar,
    MainNavigationBarMobile,
  },

  data () {
    return {
      showPromoBannerBasedOnTime: false,
    }
  },

  computed: {
    ...mapState({
      tenantInfo: state => state.billing.tenantInfo,
      isOpenedSuccessGuide: state => state.guide.stepSuccess.opened
    }),

    ...mapGetters('billing', [
      'shouldDeactivateEntity'
    ]),

    cssVars() {
      return {
        '--alert-height': this.$store.state.dashboardAlerts.alertHeight + 'px'
      }
    },

    isPwa () {
      return isPwa()
    },

    isPromoBannerVisible () {
      return false
    },

    isAgencyDemo () {
      return this.$store.getters['settings/isAgency'] && this.$store.state.settings.agency.isAgencyDemo === true
    }
  },

  created () {
    window.addEventListener('push-token', (event) => {
      if (event && event.detail) {
        this.$http.post('/api/v1/users/fcm-registration-token', {
          token: event.detail
        })
      }
    })

    window.addEventListener('push-notification', (event) => {
      if (event?.detail?.action === 'push-notification-click') {
        // TODO: implement activity dropdown that should be opened
      }
    })

    this.init()
    this.getTenantInfo()

    const threeHoursFromRegistration = this.$moment(this.$store.state.settings.general.registrationDate).clone().add(3, 'hours');
    const currentTime = this.$moment();
    if (currentTime.isSameOrAfter(threeHoursFromRegistration)) {
      this.showPromoBannerBasedOnTime = true
    }

    this.initializeSupportBeacon()
  },

  methods: {
    async init () {
      await Promise.all([
        this.$http.get('/api/v1/users/current'),
        this.$http.get('/api/v1/settings'),
        this.$http.get('/api/v1/settings/features/status'),
        this.$http.get('/api/v1/users/permissions'),
        this.$http.get('/api/v1/backoffice/plans')
      ]).then(async ([userResponse, settingsResponse, featuresResponse, permissionsResponse, plansResponse]) => {
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({ 'event': 'start_pw','pw_user_email': userResponse.data.user.email})
        store.commit('features/setFeatures', featuresResponse.data.features)
        store.commit('settings/setSettings', settingsResponse.data.settings)
        store.commit('user/setUser', userResponse.data.user)
        store.commit('user/setPermissions', permissionsResponse.data.permissions)
        store.commit('user/setAllEntitiesPermissions', permissionsResponse.data.allEntitiesPermissions)
        store.commit('colors/setPrimaryColors', settingsResponse.data.settings)
        store.commit('plans/setUserPlans', plansResponse.data.plans)

        if (window?.webkit?.messageHandlers['push-token']) {
          window.webkit.messageHandlers['push-token'].postMessage('push-token')
        }
      })
    },

    initializeSupportBeacon () {
      if (this.$store.getters['settings/isAgency']) {
       return
      }
      Gleap.setLanguage(window.language)
      Gleap.initialize('qTwmjufNmPXyIAesxuRnhSxBONPR8rEu')

      const gleapId = `${this.$store.getters['settings/getSettingByName']('tenantName', 'general')}_${this.$store.state.user.id}`
      Gleap.identify(gleapId, {
        name: this.$store.state.user.firstName + ' ' + this.$store.state.user.lastName,
        email: this.$store.state.user.email,
        plan: this.$store.getters['settings/getSettingByName']('activePlan', 'general'),
      })
    },

    getTenantInfo () {
      this.$store.commit('billing/setVisibleDialogBilling', false)
      this.$http.get(
        '/api/v1/backoffice/tenant-info'
      ).then(response => {
        this.$store.commit('billing/setTenantInfo', response.data.tenantInfo)
        this.$store.commit('features/setPlanAvailableFeatures', response.data.planAvailableFeatures)

        // When plan is not chosen. Plan is not chosen initially and if subscription is paused (couldn't be charged)
        if (this.tenantInfo.tenant.isPlanChosen === false && this.tenantInfo.expirationDate) {
          // Show plans dialog if free trial expires
          if (this.$moment().isAfter(this.tenantInfo.expirationDate)) {
            this.showPlansDialog()
            return
          }

          // Show alert if free trial is about to expire for 3 days
          if (this.$moment().isSameOrBefore(this.tenantInfo.expirationDate)) {
            const now = this.$moment()
            const expirationDataMoment = this.$moment(this.tenantInfo.expirationDate)

            const diff = expirationDataMoment.diff(now, 'days')

            if (diff <= 3 && this.$store.state.billing.wasSeenExpireAlert === false) {
              this.showExpireAlert()
            }
          }
        }

        if (this.$route.query.upgrade !== undefined) {
          this.showPlansDialog()
        }

        // Show alert if SMS Credits running out
        if (this.$store.getters['settings/isSMSCreditRunningOutAndHaveRechargedSmsCredits']() && this.$store.state.settings.sms.creditRunningLowCanBePresented === true) {
          this.showSMSCreditRunningLowAlert()
        }

        // Show deactivation dialog if there are more activated entities than allowed
        if (this.shouldDeactivateEntity) {
          this.$store.commit('billing/setSelectedPlan', this.tenantInfo.plan)
          this.showDeactivateDialog()
        }
      })
    },

    showPlansDialog () {
      this.$store.commit('billing/setBillingDialogStep', 'plans')
      this.$store.commit('billing/setVisibleDialogBilling', true)
    },

    showDeactivateDialog () {
      this.$store.commit('billing/setBillingDialogStep', 'deactivate')
      this.$store.commit('billing/setCanCloseBillingDialog', false)
      this.$store.commit('billing/setVisibleDialogBilling', true)
    },

    showExpireAlert () {
      this.$store.commit('billing/setVisibleExpireAlert', true)
    },

    showSMSCreditRunningLowAlert () {
      this.$store.commit('settings/setVisibleSMSCreditRunningLowAlert', true)
    },
  }
}
</script>

<style lang="scss">
// Dashboard
.adm-dashboard {
  display: flex;
  position: relative;
  margin-left: 72px;
  top: var(--alert-height);

  @include tablet-down {
    margin-left: 0;
  }

  &__app-banner {
    @include phone-down {
      display: none;
    }
  }

  &__bc-promotion-banner {
    margin-top: calc(60px + var(--alert-height));

    @media screen and (max-width: 1024px) {
      display: none;
    }
  }

  &__promo-2024-banner {
    margin-top: 60px;

    @media screen and (max-width: 1024px) {
      display: none;
    }
  }

  // Container
  &__container {
    background-color: $shade-200;
    min-height: calc(100vh - var(--alert-height));
    width: 100%;

    // Main Wrapper
    &__wrapper {
      width: 100%;
      margin: 60px auto 0;
      padding: 1.5rem 2.5rem 2.5rem;
      background-size: 100% auto;
      position: relative;

      &__promo-2024-banner-wrapper {
        margin: 0 auto 0;
      }

      &__bc-promo-banner-visible {
        margin: 175px auto 0;
      }

      // TODO: Remove when all page-content blocks will be replaced with a component
      // Page Content
      .page-content {
        padding: 1.5rem 1.75rem;
        background: $adm-white;
        border-radius: 5px;
        box-shadow: $box-shadow-page-content;
        overflow-x: hidden;
        min-height: 600px;

        @include tablet-down {
          padding: 1rem;
          margin: 0 -16px;
        }

        .actions {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }

        &.services-list {
          overflow: unset;
        }
      }

      // With Pagination
      .pagination-bottom {
        position: relative;
        padding-bottom: 61px;
      }

      // With Sidebar
      .content-with-sidebar {
        height: 100%;
      }

      // With Dummy Alert
      .is-dummy-alert-visible {
        padding: 1rem 1.75rem;
      }

      @include tablet-down {
        padding: 1.25rem 1rem;
        margin: 56px auto 66px;
      }
    }
  }



  // Main Navigation Bar Expanded
  &.is-main-navigation-bar-expanded {
    margin-left: 84px;

    @include tablet-down {
      margin-left: 0;
    }
  }
}
.bb-feedback-button {
  z-index: 2;

  @include tablet-down {
    display: none!important;
  }
}
</style>
