
const getDefaultState = () => {
  return {
    userPlans: [],
  }
}

const state = getDefaultState()

const getters = {
}

const mutations = {
  setUserPlans (state, payload) {
    state.userPlans = payload
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations
}
