<template>
  <div class="section select-customers">

    <!-- Title & New Customer -->
    <div class="flex-center justify-content-between mb-4">

      <!-- Title -->
      <h6>{{ $t('customers') }}</h6>
      <!-- /Title -->

      <!-- New Customer -->
      <adm-button
        v-if="isEmployeeDisconnectedFromService === false && hasPermission('edit_customer')"
        class="ml-auto"
        type="borderless"
        size="mini"
        icon-start="circle-plus"
        @click="onAddCustomerDialogOpen"
      >
        {{ $t('new_customer') }}
      </adm-button>
      <!-- /New Customer -->

    </div>
    <!-- /Title & New Customer -->

    <!-- Customer Quick Dialog -->
    <create-customer-dialog
      v-if="hasPermission('edit_customer') && visibleCustomerModal"
      type="quick"
      @click="onAddCustomerDialogOpen"
      @dialog-close="visibleCustomerModal = false"
      @customer-created="addNewCustomerBooking"
    />
    <!-- /Customer Quick Dialog -->

    <!-- Select Customers Dropdown -->
    <adm-select
      v-model="bookings"
      :disabled="isEmployeeDisconnectedFromService"
      :placeholder="$t('start_typing_a_customer_name')"
      :loading="loading"
      filterable
      multiple
      remote
      :remote-method="getCustomers"
      icon-start="customers"
      value-key="customerId"
      @focus="getCustomersOnFocus"
      @visible-change="(visibility) => getCustomersOnFocus(visibility)"
    >
      <adm-option
        v-for="customer in customers"
        :key="customer.customerId"
        :label="customer.firstName + ' ' + customer.lastName"
        :value="customer"
      />
    </adm-select>

    <adm-alert
      v-if="$store.getters['features/isFeatureEnabled']('group_booking') && isServiceSelected && isEmployeeSelected"
      class="mt-8"
      :type="computedAlert.alertType"
      :title="computedAlert.alertMessage"
    />
    <!-- /Select Customers Dropdown -->

    <!-- Customer Cards -->
    <div v-show="$store.getters['manageAppointment/isFormExtended']()">
      <customer-card
        v-for="booking in appointmentProp.bookings"
        :key="booking.id"
        ref="customerCard"
        :appointment-prop="appointmentProp"
        :booking-prop="booking"
        :capacity-maximum="capacityMaximum"
        :custom-fields="customFields"
        :applied-promo-codes="appliedPromoCodes"
        :google-maps-loader="googleMapsLoader"
        @booking-removed="removeBooking"
        @extra-removed="removeExtra"
        @added-promo-code="incrementPromoCodeUsage"
        @removed-promo-code="decrementPromoCodeUsage"
      />
    </div>
    <!-- /Customer Cards -->

  </div>
</template>

<script>
import AdmButton from '@/views/_components/button/AdmButton'
import CustomerCard from './CustomerCard/CustomerCard'
import mixinStatus from '@/mixins/common/status'
import mixinSecurity from '@/mixins/security/security'
import { _ } from 'vue-underscore'
import AdmAlert from '@/views/_components/alert/AdmAlert'
import AdmSelect from '@/views/_components/select/AdmSelect'
import AdmOption from '@/views/_components/select/AdmOption'
import { Loader } from 'google-maps'
import mixinLocation from '@/mixins/page/location'
import CreateCustomerDialog from '@/views/Customers/Dialogs/CreateCustomerDialog.vue'

export default {
  name: 'CustomerSection',

  components: {
    CreateCustomerDialog,
    AdmOption,
    AdmSelect,
    AdmAlert,
    AdmButton,
    CustomerCard,
  },

  mixins: [
    mixinStatus,
    mixinSecurity,
    mixinLocation
  ],

  props: {
    appointmentProp: {
      type: Object,
      default: () => {},
      required: true
    },
    isEmployeeDisconnectedFromService: {
      type: Boolean,
      default: false
    },
    capacityMaximum: {
      type: Number,
      default: 1
    },
    capacityAvailable: {
      type: Number,
      default: 1
    },
    servicePrice: {
      type: Number,
      default: 0,
      required: true
    },
    customFields: {
      type: Array,
      default: () => ([])
    },
  },

  data: function () {
    return {
      customers: [],
      visibleCustomerModal: false,
      alertType: 'default',
      loading: false,
      params: {
        search: '',
        page: 1,
        resultsPerPage: 100,
        appointmentCustomers: null
      },
      appliedPromoCodes: {},
      googleMapsLoader: null,
    }
  },

  computed: {
    bookings: {
      get () {
        return this.appointmentProp.bookings
      },
      set (bookings) {
        this.$set(this.appointmentProp, 'bookings', bookings)
      }
    },

    isEditAppointment () {
      return this.$store.getters['manageAppointment/getManageDialogAction']() !== 'add'
    },

    isServiceSelected () {
      return this.appointmentProp.service
    },

    isEmployeeSelected () {
      return this.appointmentProp.employee
    },

    computedAlert () {
      let alertType = 'default'
      let alertMessage = this.$t('capacity_maximum_info') + ' ' + this.capacityMaximum + '.'

      // Checks if booking number of persons exceeds available value
      let totalPersons = 0
      for (let i = 0; i < this.appointmentProp.bookings.length; i++) {
        if (this.appointmentProp.bookings[i].status === 1) {
          totalPersons += this.appointmentProp.bookings[i].additionalPersons + 1
        }

        if (totalPersons > this.capacityMaximum) {
          alertType = this.isAvailableBookingOverMaxCapacity() ? 'warning' : 'danger'
          alertMessage = this.$t('capacity_exceeded')
        }
      }

      if (this.capacityAvailable === 0) {
        alertMessage = this.$t('capacity_no_more_place')
      }

      if (this.appointmentProp.bookings.length > 0 && this.capacityAvailable > 0) {
        alertMessage = this.capacityAvailable + ' ' +
          (this.capacityAvailable === 1 ? this.$t('capacity_available_info_si') : this.$t('capacity_available_info_pl'))
      }

      return { alertMessage, alertType }
    }
  },

  watch: {
    // Watch for changes of service or employee
    'appointmentProp.service': function () {
      this.bookings.forEach(booking => {
        booking.extras = []
        booking.additionalPersons = 0
      })
    },
  },

  created () {
    if (this.isEditAppointment) {
      this.customers = this.appointmentProp.bookings.map(booking => this.prepareCustomerBooking({
        id: booking.customerId,
        firstName: booking.firstName,
        lastName: booking.lastName
      }))
      this.params.appointmentCustomers = this.appointmentProp.bookings.map(booking => booking.customerId)
    }

    this.googleMapsLoader = new Loader(process.env.VUE_APP_GOOGLE_MAPS_API_KEY, this.googleMap)
  },

  methods: {
    incrementPromoCodeUsage (promoCode) {
      if (!this.appliedPromoCodes.hasOwnProperty(promoCode)) {
        this.appliedPromoCodes[promoCode] = 1

        return
      }
      this.appliedPromoCodes[promoCode] += 1
    },

    decrementPromoCodeUsage (promoCode) {
      this.appliedPromoCodes[promoCode] -= 1
    },

    getDefaultStatusOfAppointment () {
      let defaultStringValue = this.$store.getters['settings/getSettingByName']('appointmentStatus', 'general')

      return this.appointmentStatuses.find(item => item.stringValue === defaultStringValue).value
    },

    getCustomers (search) {
      this.loading = true
      this.params.search = search

      this.$http.get(
        '/api/v1/appointments/entities/customers',
        {
          params: this.params,
        }
      ).then(response => {
        this.customers = response.data.payload.map(customer => this.prepareCustomerBooking(customer))
      }).finally(() => {
        this.loading = false
      })
    },

    getCustomersOnFocus (dropdownVisible) {
      if (typeof dropdownVisible === 'boolean') {
        if (dropdownVisible) {
          this.getCustomers()
        }

        if (!dropdownVisible) {
          this.params.search = ''
        }
      }
    },

    onAddCustomerDialogOpen () {
      this.visibleCustomerModal = true
    },

    addNewCustomerBooking (customer) {
      customer = this.prepareCustomerBooking(customer)
      customer.status = this.getDefaultStatusOfAppointment()
      this.customers.push(customer)
      this.customers.sort((a, b) => (a.firstName + ' ' + a.lastName).localeCompare(b.firstName + ' ' + b.lastName))
      this.bookings.push(customer)
      this.prepareCustomerBooking({
        id: customer.id,
        firstName: customer.firstName,
        lastName: customer.lastName
      })
    },

    prepareCustomerBooking (customer) {
      let employeeFee = 0
      let price = 0

      if (this.appointmentProp.id) {
        employeeFee = this.appointmentProp.employeeFee
        price = this.appointmentProp.price
      }

      customer = _.extend(
        customer,
        {
          label: customer.firstName + ' ' + customer.lastName,
          additionalPersons: 0,
          extras: [],
          customFields: {},
          customerId: customer.id,
          price: price,
          employeeFee: employeeFee,
          recurringData: null,
          promoCodeData: null,
          isRecurringEnabledForBooking: false,
          isPromoCodeDisabled: false,
          multiplyPriceWithNumberOfPeople: this.appointmentProp.service
            ? this.appointmentProp.service.multiplyPriceWithNumberOfPeople : false
        }
      )

      customer = _.omit(customer, ['services', 'id'])

      return customer
    },

    removeBooking (booking) {
      const bookingIndex = this.bookings.indexOf(booking)
      this.bookings[bookingIndex].extras = []
      this.bookings.splice(bookingIndex, 1)
    },

    removeExtra (booking, extra) {
      this.bookings[this.bookings.indexOf(booking)].extras.splice(
        this.bookings[this.bookings.indexOf(booking)].extras.indexOf(extra), 1
      )
    },
  }
}
</script>

