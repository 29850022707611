class AppointmentUtils {
  static calculateAppointmentPrice (appointment) {
    let price = 0

    for (let i = 0; i < appointment.bookings.length; i++) {
      const booking = appointment.bookings[i]
      let bookingPrice = this.getPriceForBookingWithDiscount(booking)

      if (booking.recurringData && booking.recurringData.bookings.length > 1) {
        bookingPrice = (this.getPriceForBookingWithoutDiscount(booking) * booking.recurringData.bookings.length)
          - this.getDiscountForBooking(booking, appointment.service)
      }

      price += (bookingPrice > 0 ? bookingPrice : 0)
    }

    return price
  }

  static getDiscountForBooking (booking, service) {
    if (!booking.discount) {
      return 0
    }
    if (!booking.recurringData || booking.recurringData.bookings.length <= 1) {
      return booking.discount
    }
    const recurringCount = booking.recurringData.bookings.length

    if (this.isPaymentForFirstBookingOnly(service) && booking.promoCodeData) {
      if (booking.promoCodeData.limit && booking.promoCodeData.limit < recurringCount) {
        return booking.discount * booking.promoCodeData.limit
      }
      return booking.discount * recurringCount
    }

    if (booking.promoCodeData.type === 'discount') {
      return booking.discount * recurringCount
    }

    return booking.discount
  }

  static getPriceForBookingWithDiscount (booking) {
    let bookingPrice = this.getPriceForBookingWithoutDiscount(booking)

    bookingPrice -= booking.discount ? parseFloat(booking.discount) : 0

    return bookingPrice
  }

  static getPriceForBookingWithoutDiscount(booking) {
    let bookingPrice = 0

    bookingPrice += booking.price * (booking.multiplyPriceWithNumberOfPeople ? (1 + booking.additionalPersons) : 1)

    for (let j = 0; j < booking.extras.length; j++) {
      bookingPrice += booking.extras[j].price * booking.extras[j].quantity * (booking.extras[j].multiplyPriceWithNumberOfPeople ? (1 + booking.additionalPersons) : 1)
    }

    if (booking.bookingId && booking.taxData && booking.taxData.taxDisplaySettings === 'exclusive') {
      if (booking.taxData.serviceTaxes) {
        for (let j = 0; j < booking.taxData.serviceTaxes.length; j++) {
          bookingPrice += booking.taxData.serviceTaxes[j].value
        }
      }

      if (booking.taxData.extrasTaxes) {
        for (let k = 0; k < booking.taxData.extrasTaxes.length; k++) {
          for (let l = 0; l < booking.taxData.extrasTaxes[k].length; l++) {
            bookingPrice += booking.taxData.extrasTaxes[k][l].value
          }
        }
      }
    }

    return bookingPrice
  }

  static getExtrasParams (appointment) {
    const extras = []

    for (let i = 0; i < appointment.bookings.length; i++) {
      for (let j = 0; j < appointment.bookings[i].extras.length; j++) {
        extras.push(JSON.stringify({
          id: appointment.bookings[i].extras[j].id,
          quantity: appointment.bookings[i].extras[j].quantity,
          numberOfPersons: 1 + appointment.bookings[i].additionalPersons
        }))
      }
    }

    return extras
  }

  static getBookedAppointmentsInPlan (value, maxValue) {
    return Math.ceil((value / maxValue) * 100)
  }

  static getStatusIconColor (status, colors) {
    if (status === 1)
      return colors['green-900']
    if (status === 2)
      return colors['yellow-900']
    if (status === 3)
      return colors['shade-800']
    if (status === 4)
      return colors['red-900']
    if (status === 5)
      return colors['shade-500']
  }

  static getStatusIcon (status) {
    if (status === 1)
      return "approved"
    if (status === 2)
      return "pending-status"
    if (status === 3)
      return "cancelled"
    if (status === 4)
      return "rejected"
    if (status === 5)
      return 'no-show'
  }

  static isPaymentForFirstBookingOnly (service) {
    return service.recurringOptions
      && service.recurringOptions.recurringAppointmentsPayment === 'first_appointment_only'
  }
}

export default AppointmentUtils
