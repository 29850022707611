<template>

  <!-- Manage Appointment -->
  <adm-modal
    :title="$store.getters['manageAppointment/getManageDialogAction']() === 'edit' ? $t('edit_appointment') : $t('add_appointment')"
    :visible="true"
    :style="{'z-index': 1000}"
    destroy-on-close
    :width="$store.getters['manageAppointment/isFormExtended']() ? '1000px' : '648px'"
    @close="closeDialog"
  >

    <!-- Add/Edit Appointment -->
    <div id="appointment">

      <!-- Appointment Box -->
      <div class="appointment-form flex">
        <!-- Appointment Form -->
        <manage-appointment-form
          ref="manageAppointmentForm"
          class="flex-grow"
          :loading="loading"
          :appointment-prop="appointment"
          :is-employee-disconnected-from-service="isEmployeeDisconnectedFromService"
          :existing-prices="existingPrices"
          :create-appointment-disabled="createAppointmentDisabled"
          :capacity-maximum="capacityMaximum"
          :capacity-available="capacityAvailable"
          :service-price="servicePrice"
          :custom-fields="customFields"
          @empty-location="handleEmptyLocation()"
        />
        <!-- /Appointment Form -->

        <!-- Appointment Info -->
        <manage-appointment-info
          v-if="$store.getters['manageAppointment/isFormExtended']()"
          class="right-side"
          :appointment-prop="appointment"
          :loading="loading"
          :existing-prices="existingPrices"
          :is-props-not-empty="isAppointmentParamsNotEmpty"
          :create-appointment-disabled="createAppointmentDisabled"
          :service-price="servicePrice"
        />
        <!-- /Appointment Info -->

      </div>
      <!-- /Appointment Box -->

      <!-- Send notification to customers -->
      <adm-checkbox
        v-if="!appointment.id && !loading"
        v-model="appointment.sendNotification"
        class="send-notification-to-customer"
      >
        {{ appointment.bookings.length > 1 ? $t('send_notification_to_customers') : $t('send_notification_to_customer') }}
      </adm-checkbox>
      <!-- Send notification to customers -->

    </div>
    <!-- /Add/Edit Appointment -->

    <!-- Action Buttons -->
    <template #footer>
      <!-- More Options -->
      <adm-button
        size="medium"
        color="grey"
        class="mr-auto"
        @click="$store.commit('manageAppointment/toggleExtendedForm')"
      >
        {{ $store.getters['manageAppointment/isFormExtended']() ? $t('less_options') : $t('more_options') }}
      </adm-button>
      <!-- /More Options -->

      <!-- Cancel -->
      <adm-button
        v-if="$screen.aboveMobile"
        size="medium"
        color="grey"
        @click="closeDialog"
      >
        {{ $t('cancel') }}
      </adm-button>
      <!-- /Cancel -->

      <!-- Save -->
      <adm-button
        size="medium"
        :loading="loadingSaveButton"
        :disabled="createAppointmentDisabled || isEmployeeDisconnectedFromService"
        @click="saveAppointment"
      >
        {{ $t('save') }}
      </adm-button>
      <!-- /Save -->

    </template>
    <!-- /Action Buttons -->

  </adm-modal>
  <!-- /Manage Appointment -->

</template>

<script>
import ManageAppointmentForm from './ManageAppointmentForm/ManageAppointmentForm'
import ManageAppointmentInfo from './ManageAppointmentInfo/ManageAppointmentInfo'
import { getAbortController, isRequestCanceled } from '@/utils/api'
import mixinSecurity from '@/mixins/security/security'
import AdmModal from '@/views/_components/modal/AdmModal.vue'
import AdmButton from '@/views/_components/button/AdmButton.vue'
import AdmCheckbox from '@/views/_components/checkbox/AdmCheckbox.vue'

export default {
  name: 'ManageAppointmentDialog',

  components: {
    AdmCheckbox,
    AdmButton,
    AdmModal,
    ManageAppointmentForm,
    ManageAppointmentInfo,
  },

  mixins: [
    mixinSecurity
  ],

  data () {
    return {
      cancelToken: getAbortController(),
      appointment: {
        bookings: [],
        service: null,
        employee: null,
        selectedSlot: null,
        startDate: null,
        startTime: '',
        startDateTime: '',
        location: null,
        changedExtra: {},
        sendNotification: true
      },
      loadingAppointment: false,
      loadingCustomFields: false,
      existingPrices: {
        servicePriceForExistingAppointment: null,
        employeeFeeForExistingAppointment: null,
      },
      isThereAnyLocation: true,
      customFields: [],
      loadingSaveButton: false,
      validCustomerCards: 0,
    }
  },

  computed: {
    queryParams () {
      return {
        service: this.appointment?.service?.id,
        employee: this.appointment?.employee?.id,
        location: this.appointment?.location?.id,
        selectedSlot: this.appointment?.selectedSlot ? JSON.stringify(this.appointment?.selectedSlot) : undefined,
        extras: []
      }
    },

    // When employee is not assigned anymore to service, but it has appointment because it was assigned before
    isEmployeeDisconnectedFromService () {
      return (this.appointment.id && this.appointment.employee?.id && this.appointment.service?.id) || false
    },

    isAppointmentParamsNotEmpty () {
      return Object.keys(this.appointment).some(propName => {
        const appointmentProp = this.appointment[propName]

        if (appointmentProp !== null) {
          if (
            typeof appointmentProp === 'object' && Object.keys(appointmentProp).length > 0 ||
            typeof appointmentProp === 'string' && appointmentProp
          ) {
            return true
          }
        }

        return false
      })
    },

    createAppointmentDisabled () {
      let areAllRecurringBookingsCreated = true
      if (this.appointment.id === undefined) {
        this.appointment.bookings.forEach(val => {
          if (val.hasOwnProperty('recurringData') && val.recurringData) {
            if (val.recurringData.bookings.length === 0) {
              areAllRecurringBookingsCreated = false
            }
          }
        })
      }

      return !this.appointment.service ||
        !this.appointment.employee ||
        (!this.appointment.location && this.isThereAnyLocation) ||
        !this.appointment.startDate ||
        !this.appointment.startTime ||
        this.appointment.bookings.length === 0 ||
        areAllRecurringBookingsCreated === false ||
        (this.capacityAvailable < 0 && !this.isAvailableBookingOverMaxCapacity())
    },

    capacityAvailable () {
      const approvedBookings = this.appointment.bookings.filter(booking => booking.status === 1)
      const customersAdded = approvedBookings.length
      const additionalPersons = approvedBookings.length > 0 ? approvedBookings.map(booking => booking.additionalPersons).reduce((prev, next) => prev + next) : 0

      return this.capacityMaximum - customersAdded - additionalPersons
    },

    capacityMaximum () {
      let maxCapacity = 1

      // Applies service capacity if only service selected
      if (this.appointment.service) {
        maxCapacity = this.appointment.service.maxCapacity
      }

      // Applies employee capacity if employee selected after service
      if (this.appointment.employee) {
        maxCapacity = this.appointment.employee.maxCapacity
      }

      // Applies employee capacity if employee selected before service
      if (this.appointment.service?.employeeMaxCapacity) {
        maxCapacity = this.appointment.service.employeeMaxCapacity
      }

      // Applies fixed appointment capacity on edit and duplicate
      if (this.$store.getters['manageAppointment/getManageDialogAction']() !== 'add') {
        maxCapacity = this.appointment.maxCapacity
      }

      return maxCapacity
    },

    servicePrice () {
      let price = 0

      // Applies service price if only service selected
      if (this.appointment.service?.servicePrice) {
        price = this.appointment.service.servicePrice
      }

      // Applies employee price if employee selected after service
      if (this.appointment.employee?.price) {
        price = this.appointment.employee.price
      }

      // Applies employee price if employee selected before service
      if (this.appointment.service?.employeePrice) {
        price = this.appointment.service.employeePrice
      }

      // Applies fixed appointment price on edit
      if (this.$store.getters['manageAppointment/getManageDialogAction']() !== 'add') {
        price = this.appointment.price
      }

      return price
    },

    loading () {
      return this.loadingAppointment || this.loadingCustomFields
    }
  },

  watch: {
    'servicePrice': function () {
      this.updateBookingsPrice()
    },

    'appointment.bookings': function () {
      this.updateBookingsPrice()
    },

    'appointment.changedExtra': {
      deep: true,
      handler: function () {
        if (this.$store.getters['manageAppointment/getManageDialogAction']() !== 'edit') {
          this.resetRecurringBookings()
        }
      }
    },

    queryParams(newValue) {
      this.$store.commit('manageAppointment/setQueryParams', newValue)

      if (this.$store.getters['manageAppointment/getManageDialogAction']() !== 'edit') {
        this.resetRecurringBookings()
      }
    },
  },

  created () {
    if (Number.isInteger(this.$store.getters['manageAppointment/getManageDialogAppointmentId']())) {
      this.getAppointment()
    }

    if (this.$store.getters['manageAppointment/getManageDialogAction']() === 'add') {
      const manageDialogParams = this.$store.getters['manageAppointment/getManageDialogParams']()

      if (manageDialogParams.employee) {
        this.appointment.employee = manageDialogParams.employee
      }

      if (manageDialogParams?.dateTime) {
        const hasTime = this.$moment.utc(manageDialogParams.dateTime, 'YYYY-MM-DDTHH:mm:ssZ', true).isValid()
        const momentDateTime = this.$moment.utc(manageDialogParams.dateTime)

        this.appointment.startDateTime = momentDateTime.format('YYYY-MM-DD HH:mm')
        this.appointment.startDate = momentDateTime.format('YYYY-MM-DD')

        if (hasTime) {
          this.appointment.startTime = momentDateTime.format('HH:mm')
        }
      }
    }

    if (this.$store.getters['features/isFeatureEnabled']('custom_fields')) {
      this.getCustomFields()
    }
  },

  beforeDestroy () {
    this.$store.commit('manageAppointment/resetState')
    this.cancelToken.abort()
  },

  methods: {
    async getAppointment () {
      try {
        this.loadingAppointment = true

        const response = await this.$http.get(
          '/api/v1/appointments/' + this.$store.getters['manageAppointment/getManageDialogAppointmentId'](),
          { signal: this.cancelToken.signal }
        )

        if (response.status !== 200) {
          this.$message({ message: 'Something went wrong', type: 'error', showClose: true })
        }

        await this.fillAppointmentFromResponse(response.data.appointment)
      } catch (e) {
        if (isRequestCanceled(e)) return
        this.$message({ message: 'Something went wrong', type: 'error', showClose: true })
      } finally {
        this.loadingAppointment = false
      }
    },

    fillAppointmentFromResponse (data) {
      const isDuplicateAction = this.$store.getters['manageAppointment/getManageDialogAction']() === 'duplicate'

      this.appointment.id = isDuplicateAction ? null : data.id
      this.appointment.service = data.service
      this.appointment.employee = isDuplicateAction && data.employee.employeeDisconnected ? null : data.employee
      this.appointment.location = data.location
      this.appointment.price = data.price
      this.appointment.employeeFee = data.employeeFee
      this.appointment.maxCapacity = data.maxCapacity

      const momentDateTime = this.$moment(data.startDateTime)
      this.appointment.startDateTime = momentDateTime.format('YYYY-MM-DD HH:mm')
      this.appointment.startDate = isDuplicateAction ? null : momentDateTime.format('YYYY-MM-DD')
      this.appointment.startTime = isDuplicateAction ? null : momentDateTime.format('HH:mm')

      this.existingPrices = {
        servicePriceForExistingAppointment: this.appointment.price,
        employeeFeeForExistingAppointment: this.appointment.employeeFee,
      }

      for (let i = 0; i < data.bookings.length; i++) {
        const bookingData = data.bookings[i];
        this.appointment.bookings.push({
          additionalPersons: bookingData.additionalPersons,
          bookingId: bookingData.id,
          customerId: bookingData.customer.id,
          customFields: bookingData.customFields,
          email: bookingData.customer.email,
          extras: [],
          firstName: bookingData.customer.firstName,
          lastName: bookingData.customer.lastName,
          status: bookingData.status,
          price: bookingData.price,
          employeeFee: bookingData.employeeFee,
          discount: bookingData.discount,
          taxData: bookingData.taxData,
          deposit: bookingData.deposit,
          timezone: bookingData.timezone,
          promoCode: isDuplicateAction ? null : bookingData.promoCode,
          appliedPromoData: isDuplicateAction ? null : bookingData.appliedPromoData,
          promoCodeData: null,
          recurringData: null,
          isRecurringEnabledForBooking: false,
          isPromoCodeDisabled: bookingData.isPromoCodeDisabled,
          multiplyPriceWithNumberOfPeople: bookingData.multiplyPriceWithNumberOfPeople,
        })

        for (let j = 0; j < bookingData.extras.length; j++) {
          this.appointment.bookings[i].extras.push({
            bookingToExtraId: bookingData.extras[j].id,
            id: bookingData.extras[j].extra.id,
            maxQuantity: bookingData.extras[j].extra.maxQuantity,
            name: bookingData.extras[j].extra.name,
            price: bookingData.extras[j].price,
            quantity: bookingData.extras[j].quantity,
            multiplyPriceWithNumberOfPeople: bookingData.extras[j].multiplyPriceWithNumberOfPeople,
            taxRate: bookingData.extras[j].taxRate,
          })

          if (typeof bookingData.extras[j].extra.duration !== 'undefined') {
            this.appointment.bookings[i].extras[j].duration = bookingData.extras[j].extra.duration
          }
        }
      }
    },

    async saveAppointment () {
      this.validCustomerCards = 0
      let customerCards = this.$refs.manageAppointmentForm.$refs.customerSection.$refs.customerCard

      // Validate Customer Cards (Custom Fields)
      for (let i = 0; i < customerCards.length; i++) {
        if (customerCards[i].$refs.form) {
          await customerCards[i].$refs.form.validate(async (valid) => {
            if (valid) {
              this.validCustomerCards++
            } else {
              customerCards[i].customFieldsVisible = true
              return false
            }
          })
        } else {
          this.validCustomerCards++
        }
      }

      if (this.appointment.selectedSlot?.o === true || this.appointment.selectedSlot?.b === 0) {
        await this.$admConfirm.show(
          this.$t('are_you_sure_you_want_to_proceed'),
          this.getSaveAppointmentConfirmationText(),
          {
            confirmButtonText: this.$t('confirm')
          }
        )
      }

      // Save Appointment if all Customer Cards are valid
      if (this.validCustomerCards === customerCards.length) {
        try {
          this.loadingSaveButton = true

          let files = []
          let attachmentCustomFieldKeys = []

          this.appointment.bookings.forEach((value,bookingKey) => {
            Object.entries(value.customFields).filter(([key, file]) => {
              if (file !== null && file.raw instanceof File) {
                files[bookingKey + '_' + key] = file.raw
                attachmentCustomFieldKeys.push(bookingKey + '_' + key)
              }
            });
          })

          if (Object.keys(files).length) {
            let formData = new FormData();
            Object.entries(files).filter(([key, value]) => {
              formData.append('attachmentCustomField_' + key, value)
            })

            await this.$http.post(
              '/api/v1/public/attachment',
              formData
            ).then((response) => {
              Object.entries(response.data).filter(([key,value]) => {
                let field = this.appointment.bookings[key.split('_')[1]]
                if (field.customFields[key.split('_')[2]]) {
                  field.customFields[key.split('_')[2]] = JSON.stringify(value)
                }
              })
            })
          }

          let appointmentResponse = await this.$http.post(
            `/api/v1/appointments${this.appointment.id ? '/' + this.appointment.id : ''}`,
            this.appointment
          )

          if (appointmentResponse.status === 202) {
            this.$message({
              message: this.$t(''+appointmentResponse.data.message),
              type: 'error',
              showClose: true
            })
          } else {
            this.$message({
              message: typeof this.appointment.id === 'undefined' ? this.$t('appointment_added') : this.$t('appointment_updated'),
              type: 'success',
              showClose: true
            })
          }

          this.$store.commit('manageAppointment/closeManageDialog')
          this.$store.commit('view/increaseRouterViewKey')
        } catch (e) {
          if (e.response.data.message) {
            return this.$message({ message: e.response.data.message, type: 'error', showClose: true })
          }

          this.$message({ message: this.$t('something_went_wrong'), type: 'error', showClose: true })
        } finally {
          this.loadingSaveButton = false
        }
      }
    },

    updateBookingsPrice () {
      if (!this.appointment.id) {
        for (let i = 0; i < this.appointment.bookings.length; i++) {
          this.appointment.bookings[i].price = this.servicePrice
        }
      }
    },

    handleEmptyLocation () {
      this.isThereAnyLocation = false
    },

    async getCustomFields () {
      this.loadingCustomFields = true

      try {
        const response = await this.$http.get(
          '/api/v1/appointments/custom-fields',
          { params: { showDeleted: true }, signal: this.cancelToken.signal }
        )

        this.customFields = response.data.customFields
      } catch (e) {
        if (isRequestCanceled(e)) return
        this.$message({ message: this.$t('something_went_wrong'), type: 'error', showClose: true })
      } finally {
        this.loadingCustomFields = false
      }
    },

    getSaveAppointmentConfirmationText () {
      const selectedSlot = this.appointment.selectedSlot
      let text = this.$t('the_appointment_will_be')
      const warnings = []

      selectedSlot?.o === true && warnings.push(this.$t('outside_of_working_hours').toLowerCase())
      selectedSlot?.b === 0 && warnings.push(this.$t('in_the_already_busy_time_slot').toLowerCase())
      this.capacityAvailable < 0 && warnings.push(this.$t('above_maximum_capacity').toLowerCase())

      const numberOfWarnings = warnings.length
      text += ` ${ warnings[0] }`

      if (numberOfWarnings === 3) {
        text += `, ${ warnings[1] }`
      }

      if (numberOfWarnings > 1) {
        text += ` ${ this.$t('and') } ${ warnings.pop() }`
      }

      text += '.'

      return text
    },

    resetRecurringBookings () {
      let resetRecurringBookings = false
      this.appointment.bookings.forEach(val => {
        if (val.hasOwnProperty('recurringData') && val.recurringData && val.recurringData.bookings.length !== 0) {
          resetRecurringBookings = true
          this.$set(val.recurringData, 'bookings', [])
          this.$set(val, 'isRecurringEnabledForBooking', false)
        }
      })

      if (resetRecurringBookings) {
        this.sendMessageForResetOfRecurringBooking()
      }
    },

    sendMessageForResetOfRecurringBooking () {
      this.$message({
        message: this.$t('recurring_bookings_removed'),
        type: 'warning',
        showClose: true,
        duration: 6000
      })
    },

    closeDialog () {
      this.$store.commit('manageAppointment/closeManageDialog')
    },
  },
}
</script>

<style scoped lang="scss">
#appointment {
  .appointment-form {
    @include media-breakpoint-down($bp-small-max) {
      padding: 1rem;
      display: block;
      margin: 0 -1rem;
    }

    .left-side {
      @include media-breakpoint-down($bp-small-max) {
        width: 100%;
        padding-right: 0;
        border: none;
      }
    }

    .right-side {
      border-left: 1px solid $shade-300;
      width: 40%;
      padding-left: 1.5rem;
      margin-left: 1.5rem;
      position: relative;
      display: flex;
      flex-direction: column;

      @include phone-down {
        padding: 0;
        width: 100%;
        margin-left: 0;
        border: 0;
      }
    }
  }

  .send-notification-to-customer {
    margin-top: 24px;
  }
}
</style>
