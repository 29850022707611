<template>

  <!-- Main Navigation Bar -->
  <div
    class="main-nav-bar custom-scrollbar"
    :class="[
      { 'is-expanded': $store.state.menu.isExpanded === true },
      { 'is-alert-visible': isAlertVisible }
    ]"
  >

    <!-- Logo -->
    <router-link v-if="$store.getters['settings/isLogoSquareVisible']" :to="{ name: 'home' }" class="main-nav-bar__logo">
      <img
        v-if="$store.getters['settings/isAgency']"
        :src="$store.state.settings.agency.agencyLogoSquareUrl"
        alt="logo"
      >
      <img v-if="!$store.getters['settings/isAgency']" alt="logo" src="@/assets/img/logo/logo-square.svg">
    </router-link>
    <!-- /Logo -->

    <!-- Expand Menu -->
    <div class="main-nav-bar__expand" @click="$store.commit('menu/toggleMenu')">
      <template v-if="$store.state.menu.isExpanded === false">
        <svg width="6" height="8" viewBox="0 0 6 8" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:class="'main-nav-bar__expand__arrow-left'" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path opacity=".5" d="M4.694 0c.209.001.416.047.606.133a1.173 1.173 0 01.7 1.06v5.613a1.173 1.173 0 01-.7 1.06 1.4 1.4 0 01-1.473-.173l-3.4-2.807a1.133 1.133 0 010-1.773l3.4-2.806c.245-.2.551-.307.867-.307z" fill="#99A1B1"/></svg>
        <svg width="6" height="8" viewBox="0 0 6 8" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:class="'main-nav-bar__expand__arrow-right'" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path opacity=".5" d="M1.306 8A1.486 1.486 0 01.7 7.867a1.173 1.173 0 01-.7-1.06V1.194A1.173 1.173 0 01.7.134a1.4 1.4 0 011.473.173l3.4 2.807a1.133 1.133 0 010 1.773l-3.4 2.806A1.373 1.373 0 011.306 8z" fill="#99A1B1"/></svg>
      </template>
      <template v-if="$store.state.menu.isExpanded === true">
        <svg width="6" height="8" viewBox="0 0 6 8" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:class="'main-nav-bar__expand__arrow-left'" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path opacity=".5" d="M1.306 8A1.486 1.486 0 01.7 7.867a1.173 1.173 0 01-.7-1.06V1.194A1.173 1.173 0 01.7.134a1.4 1.4 0 011.473.173l3.4 2.807a1.133 1.133 0 010 1.773l-3.4 2.806A1.373 1.373 0 011.306 8z" fill="#99A1B1"/></svg>
        <svg width="6" height="8" viewBox="0 0 6 8" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:class="'main-nav-bar__expand__arrow-right'" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path opacity=".5" d="M4.694 0c.209.001.416.047.606.133a1.173 1.173 0 01.7 1.06v5.613a1.173 1.173 0 01-.7 1.06 1.4 1.4 0 01-1.473-.173l-3.4-2.807a1.133 1.133 0 010-1.773l3.4-2.806c.245-.2.551-.307.867-.307z" fill="#99A1B1"/></svg>
      </template>
    </div>
    <!-- /Expand Menu -->

    <!-- Top Menu -->
    <transition name="fade">
      <div class="main-nav-bar__top">
        <navigation-item
          v-for="menuItem in getNavigationItems()"
          :key="menuItem.name"
          :disable-tooltip="$store.state.menu.isExpanded"
          :icon="menuItem.icon"
          :name="menuItem.name"
          :route="menuItem.route"
          :route-name="menuItem.routeName"
          :guide="menuItem.guide"
        />
      </div>
    </transition>
    <!-- /Top Menu -->

    <!-- Bottom Menu -->
    <transition name="fade">
      <div class="main-nav-bar__bottom">
        <navigation-item
          v-for="menuItem in getBottomNavigationItems()"
          :key="menuItem.name"
          :disable-tooltip="$store.state.menu.isExpanded"
          :icon="menuItem.icon"
          :name="menuItem.name"
          :route="menuItem.route"
          :route-name="menuItem.routeName"
          :guide="menuItem.guide"
        />
      </div>
    </transition>
    <!-- /Bottom Menu -->

  </div>
  <!-- /Main Navigation Bar -->

</template>

<script>
import NavigationItem from '@/views/Dashboard/MainNavigationBar/MainNavigationItem/MainNavigationItem'
import { isPwa } from '@/utils/isPwa'
import mixinFinanceRoute from '@/mixins/security/financeRoute'
import { mapState } from 'vuex'

export default {
  name: 'MainNavigationBar',

  components: {
    NavigationItem
  },

  mixins: [
    mixinFinanceRoute
  ],

  props: {
    isAlertVisible: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    ...mapState({
      tenantInfo: state => state.billing.tenantInfo,
    }),
  },

  methods: {
    getNavigationItems () {
      const navigationItems = [
        {
          name: this.$t('dashboard'),
          routeName: 'dashboard',
          icon: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path class="customize-fill" d="M12 5a7 7 0 00-5.229 11.654c.469-.459 1.055-.804 1.645-1.05A8.173 8.173 0 0111.5 15c1.037 0 2.136.207 3.084.603.805.337 1.6.855 2.103 1.596A7 7 0 0012 5zm0 14a6.968 6.968 0 01-3.684-1.047c.217-.178.506-.352.872-.505A6.175 6.175 0 0111.5 17c.791 0 1.625.161 2.312.448.612.257 1.01.57 1.214.866A6.972 6.972 0 0112 19zm-9-7a9 9 0 1118 0 9 9 0 01-18 0zm11.832-2.445a1 1 0 00-1.664-1.11l-2 3a1 1 0 101.664 1.11l2-3z" fill="#005AEE"/></svg>`,
          guide: {
            title: '',
            description: '',
          },
          permissions: 'read_dashboard'
        },
        {
          name: this.$t('calendar'),
          routeName: 'calendar',
          icon: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path class="customize-fill" d="M17.6 4.32h-.933v-.96c0-.576-.374-.96-.934-.96s-.933.384-.933.96v.96H9.2v-.96c0-.576-.373-.96-.933-.96s-.934.384-.934.96v.96H6.4c-1.587 0-2.8 1.248-2.8 2.88v11.52c0 1.632 1.213 2.88 2.8 2.88h11.2c1.587 0 2.8-1.248 2.8-2.88V7.2c0-1.632-1.213-2.88-2.8-2.88zm.933 5.184V18.72c0 .576-.373.96-.933.96H6.4c-.56 0-.933-.384-.933-.96V7.2c0-.576.373-.96.933-.96h.933v.96c0 .576.374.96.934.96s.933-.384.933-.96v-.96h5.6v.96c0 .576.373.96.933.96s.934-.384.934-.96v-.96h.933c.56 0 .933.384.933.96v2.304z" fill="#005AEE"/></svg>`,
          guide: {
            title: '',
            description: '',
          },
          permissions: 'read_calendar'
        },
        {
          name: this.$t('appointments'),
          routeName: 'appointments',
          icon: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path class="customize-fill" d="M20 11.83a1 1 0 00-1 1v5.57a.6.6 0 01-.6.6H5.6a.6.6 0 01-.6-.6V5.6a.6.6 0 01.6-.6h9.57a1 1 0 100-2H5.6A2.61 2.61 0 003 5.6v12.8A2.61 2.61 0 005.6 21h12.8a2.61 2.61 0 002.6-2.6v-5.57a1 1 0 00-1-1z" fill="#005AEE"/><path class="customize-fill" d="M10.72 11a1 1 0 00-1.44 1.38l2.22 2.33a1 1 0 001.44.01l6.78-7a1.005 1.005 0 00-1.117-1.622c-.121.052-.231.127-.323.222l-6.05 6.26L10.72 11z" fill="#005AEE"/></svg>`,
          guide: {
            title: '',
            description: '',
          },
          permissions: 'read_appointment'
        },
        {
          name: this.$t('employees'),
          routeName: 'employees',
          icon: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path class="customize-fill" fill-rule="evenodd" clip-rule="evenodd" d="M3.401 6.022A3.312 3.312 0 006.71 9.331a3.312 3.312 0 003.31-3.309 3.312 3.312 0 00-3.31-3.308A3.311 3.311 0 003.4 6.022zm3.305-1.441c-.794 0-1.44.647-1.44 1.441s.646 1.441 1.44 1.441c.794 0 1.442-.647 1.442-1.44 0-.795-.648-1.442-1.442-1.442zM23.04 18.507a.96.96 0 010 1.922H.96a.962.962 0 010-1.922H17.86l3.177-7.264a.252.252 0 00-.017-.247.245.245 0 00-.21-.116h-8.337c-.101 0-.191.058-.231.149l-2.735 6.135-.135.088H5.883a.961.961 0 110-1.921H8.22l.876-1.968a3.731 3.731 0 00-2.509-.988c-2.079 0-3.772 1.713-3.772 3.816a.93.93 0 01-.925.934.93.93 0 01-.926-.934c0-3.134 2.522-5.683 5.623-5.683 1.18 0 2.319.378 3.293 1.094l.605-1.354a2.175 2.175 0 011.988-1.29h8.336c.738 0 1.42.37 1.824.99.403.622.463 1.396.161 2.07l-2.835 6.49h3.082z" fill="#005AEE"/></svg>`,
          guide: {
            title: this.$t('your_employees_page'),
            description: this.$t('your_employees_page_desc'),
          },
          permissions: ['read_employee', 'edit_own_employee_profile']
        },
        {
          name: this.$t('customers'),
          routeName: 'customers',
          icon: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path class="customize-fill" d="M9.002 11a4 4 0 100-8 4 4 0 000 8zm0-6a2 2 0 110 4 2 2 0 010-4zM17.002 13a3 3 0 100-6 3 3 0 000 6zm0-4a1 1 0 110 2 1 1 0 010-2zM17.002 14a5 5 0 00-3.06 1.05A7 7 0 002.002 20a1 1 0 102 0 5 5 0 1110 0 1 1 0 102 0 6.9 6.9 0 00-.86-3.35 3 3 0 014.86 2.35 1 1 0 102 0 5 5 0 00-5-5z" fill="#005AEE"/></svg>`,
          guide: {
            title: '',
            description: '',
          },
          permissions: 'read_customer'
        },
        {
          name: this.$t('services'),
          routeName: 'services',
          icon: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path class="customize-fill" d="M20.12 6.71l-2.83-2.83A3 3 0 0015.17 3H8.83a3 3 0 00-2.12.88L3.88 6.71A3 3 0 003 8.83V18a3 3 0 003 3h12a3 3 0 003-3V8.83a3 3 0 00-.88-2.12zm-12-1.42A1.05 1.05 0 018.83 5h6.34c.265.004.518.107.71.29L17.59 7H6.41l1.71-1.71zM18 19H6a1 1 0 01-1-1V9h14v9a1 1 0 01-1 1z" fill="#005AEE"/><path class="customize-fill" d="M15 11a1 1 0 00-1 1 2 2 0 01-4 0 1 1 0 10-2 0 4 4 0 108 0 1 1 0 00-1-1z" fill="#005AEE"/></svg>`,
          guide: {
            title: this.$t('your_services_page'),
            description: this.$t('your_services_page_desc'),
          },
          permissions: 'read_service'
        },
        {
          name: this.$t('locations'),
          routeName: 'locations',
          icon: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path class="customize-fill" fill-rule="evenodd" clip-rule="evenodd" d="M14.897 9.598a2.896 2.896 0 11-5.792 0 2.896 2.896 0 015.792 0zm-2 0a.896.896 0 11-1.792 0 .896.896 0 011.792 0z" fill="#005AEE"/><path class="customize-fill" fill-rule="evenodd" clip-rule="evenodd" d="M9.334 20.396c1.113 2.138 4.23 2.136 5.343-.003l3.92-7.547c.573-1.04.902-2.23.902-3.495 0-4.06-3.357-7.351-7.5-7.351C7.857 2 4.5 5.291 4.5 9.351c0 1.181.281 2.297.785 3.285l4.049 7.76zm7.512-8.517l-.013.023-3.93 7.568c-.369.706-1.432.702-1.796.003L7.063 11.72A5.211 5.211 0 016.5 9.35C6.5 6.433 8.924 4 12 4c3.075 0 5.499 2.433 5.499 5.351 0 .915-.237 1.774-.653 2.528z" fill="#005AEE"/></svg>`,
          guide: {
            title: this.$t('your_locations_page'),
            description: this.$t('your_locations_page_desc'),
          },
          permissions: 'read_location'
        },
        {
          name: this.$t('finance'),
          routeName: this.getFinanceRoute(),
          icon: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path class="customize-fill" fill-rule="evenodd" clip-rule="evenodd" d="M12.002 15a3 3 0 100-6 3 3 0 000 6zm-1-3a1 1 0 112 0 1 1 0 01-2 0z" fill="#005AEE"/><path class="customize-fill" fill-rule="evenodd" clip-rule="evenodd" d="M19.002 5a3 3 0 013 3v8a3 3 0 01-3 3h-14a3 3 0 01-3-3V8a3 3 0 013-3h14zm-.685 2h.685a1 1 0 011 1v.685A1.286 1.286 0 0118.317 7zm1.185 3.5c.17 0 .337-.014.5-.041v3.082A3 3 0 0016.543 17H7.46a3 3 0 00-3.459-3.459V10.46A3 3 0 007.46 7h9.083a3 3 0 002.959 3.5zm.5 5.5v-.685A1.286 1.286 0 0018.317 17h.685a1 1 0 001-1zm-14.215.5c0 .177-.036.346-.1.5h-.685a1 1 0 01-1-1v-.685A1.286 1.286 0 015.788 16.5zM4.002 8.685V8a1 1 0 011-1h.684a1.286 1.286 0 01-1.685 1.685z" fill="#005AEE"/></svg>`,
          guide: {
            title: '',
            description: '',
          },
          permissions: ['read_coupon', 'read_tax', 'read_invoice_transaction', 'read_employee_commission', 'read_gift_card']
        },
        {
          name: this.$t('features_&_integrations'),
          routeName: 'features',
          icon: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path class="customize-fill" d="M12 7a5 5 0 00-3 9v4a2 2 0 002 2h2a2 2 0 002-2v-4a5 5 0 00-3-9zm1.5 7.59a1 1 0 00-.5.87V20h-2v-4.54a1 1 0 00-.5-.87A3 3 0 019 12a3 3 0 016 0 3 3 0 01-1.5 2.59zM12 6a1 1 0 001-1V3a1 1 0 00-2 0v2a1 1 0 001 1zM21 11h-2a1 1 0 000 2h2a1 1 0 000-2zM5 11H3a1 1 0 000 2h2a1 1 0 000-2zM7.66 6.42L6.22 5a1.012 1.012 0 10-1.39 1.47l1.44 1.39a1 1 0 00.73.28 1 1 0 00.72-.31 1 1 0 00-.06-1.41zM19.19 5.05a1 1 0 00-1.41 0l-1.44 1.37a1 1 0 000 1.41.999.999 0 00.72.31 1 1 0 00.69-.28l1.44-1.39a1 1 0 000-1.42z" fill="#005AEE"/></svg>`,
          guide: {
            title: this.$t('features_&_integrations'),
            description: '',
          },
          permissions: 'edit_settings'
        },
        {
          name: this.$t('rating_review'),
          routeName: 'rating-review',
          icon: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path class="customize-fill" d="M17.545 21.5a1 1 0 01-.46-.11l-5.1-2.67-5.1 2.67a1 1 0 01-1.45-1.06l1-5.63-4.12-4a1 1 0 01-.25-1 1 1 0 01.81-.68l5.7-.83 2.51-5.13a1 1 0 011.8 0l2.54 5.12 5.7.83a1 1 0 01.81.68 1 1 0 01-.25 1l-4.12 4 1 5.63a1 1 0 01-.4 1 1 1 0 01-.62.18zm-5.56-4.9a.92.92 0 01.46.11l3.77 2-.72-4.21a1 1 0 01.29-.89l3-2.93-4.2-.62a1 1 0 01-.71-.56l-1.89-3.75-1.89 3.75a1 1 0 01-.75.54l-4.2.62 3 2.93a1 1 0 01.29.89l-.72 4.16 3.77-2a.92.92 0 01.5-.04z" fill="#005AEE"/></svg>`,
          guide: {
            title: this.$t('rating_review'),
            description: '',
          },
          permissions: 'edit_settings'
        },
      ]

      return this.filterNavigationItems(navigationItems)
    },

    getBottomNavigationItems () {
      const navigationItems = [
        {
          name: this.$t('customize'),
          routeName: 'customize',
          icon: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path class="customize-fill" d="M19.54 5.08A10.609 10.609 0 0011.91 2a10 10 0 00-.05 20 2.58 2.58 0 002.53-1.89 2.52 2.52 0 00-.57-2.28.499.499 0 01.37-.83h1.65A6.15 6.15 0 0022 11.33a8.48 8.48 0 00-2.46-6.25zM15.88 15h-1.65a2.49 2.49 0 00-1.87 4.15.49.49 0 01.12.49c-.05.21-.28.34-.59.36a8.001 8.001 0 01-7.82-9.11A8.1 8.1 0 0111.92 4H12a8.47 8.47 0 016.1 2.48 6.499 6.499 0 011.9 4.77A4.17 4.17 0 0115.88 15z" fill="#005AEE"/><path class="customize-fill" d="M12 8a1.5 1.5 0 100-3 1.5 1.5 0 000 3zM15.25 7.2a1.5 1.5 0 101.501 2.599A1.5 1.5 0 0015.25 7.2zM8.75 7.2a1.5 1.5 0 10-1.498 2.6A1.5 1.5 0 008.75 7.2zM6.16 11.26a1.5 1.5 0 102.08.4 1.492 1.492 0 00-2.08-.4z" fill="#005AEE"/></svg>`,
          guide: {
            title: '',
            description: '',
          },
          permissions: 'edit_settings'
        },
        {
          name: this.$t('settings'),
          routeName: 'settings',
          icon: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path class="customize-fill" d="M8.61 22a2.25 2.25 0 01-1.35-.46L5.19 20a2.37 2.37 0 01-.49-3.22 2.06 2.06 0 00.23-1.86l-.06-.16a1.83 1.83 0 00-1.12-1.22h-.16a2.34 2.34 0 01-1.48-2.94L2.93 8a2.18 2.18 0 011.948-1.642c.288-.02.578.017.852.112a1.93 1.93 0 001.78-.29l.13-.1a1.94 1.94 0 00.73-1.51v-.24A2.32 2.32 0 0110.66 2h2.55a2.26 2.26 0 011.6.67 2.37 2.37 0 01.68 1.68v.28a1.762 1.762 0 00.69 1.43l.11.08a1.74 1.74 0 001.59.26l.34-.11A2.26 2.26 0 0121.1 7.8l.79 2.52a2.36 2.36 0 01-1.46 2.93l-.2.07A1.89 1.89 0 0019 14.6a2 2 0 00.25 1.65l.26.38a2.38 2.38 0 01-.5 3.23L17 21.41a2.241 2.241 0 01-3.22-.53l-.12-.17a1.75 1.75 0 00-1.5-.78 1.8 1.8 0 00-1.43.77l-.23.33A2.25 2.25 0 019 22a2 2 0 01-.39 0zM4.4 11.62a3.83 3.83 0 012.38 2.5v.12a4 4 0 01-.46 3.62.38.38 0 000 .51L8.47 20a.251.251 0 00.37-.07l.23-.33a3.77 3.77 0 016.2 0l.12.18a.3.3 0 00.18.12.25.25 0 00.19-.05l2.06-1.56a.36.36 0 00.07-.49l-.26-.38A4.002 4.002 0 0117.1 14a3.921 3.921 0 012.49-2.61l.2-.07a.34.34 0 00.19-.44l-.78-2.49a.35.35 0 00-.2-.19.21.21 0 00-.19 0l-.34.11a3.74 3.74 0 01-3.43-.57L15 7.65a3.76 3.76 0 01-1.49-3v-.31a.37.37 0 00-.1-.26.31.31 0 00-.21-.08h-2.54a.31.31 0 00-.29.33v.25a3.9 3.9 0 01-1.52 3.09l-.13.1a3.91 3.91 0 01-3.63.59.22.22 0 00-.14 0 .28.28 0 00-.12.15L4 11.12a.36.36 0 00.22.45l.18.05z" fill="#005AEE"/><path class="customize-fill" d="M12 15.5a3.5 3.5 0 110-7 3.5 3.5 0 010 7zm0-5a1.5 1.5 0 100 3 1.5 1.5 0 000-3z" fill="#005AEE"/></svg>`,
          guide: {
            title: '',
            description: '',
          },
          permissions: 'edit_settings'
        },
      ]



      return this.filterNavigationItems(navigationItems)
    },

    filterNavigationItems (navigationItems) {
      // Move default first page to the beginning of the array
      const defaultFirstPage = this.$store.getters['settings/getSettingByName']('defaultFirstPage', 'general')
      const firstPageItemIndex = navigationItems.findIndex(navigationItem => navigationItem.routeName === defaultFirstPage)

      if (firstPageItemIndex !== -1) {
        const firstPageItem = navigationItems[firstPageItemIndex]

        navigationItems.splice(firstPageItemIndex, 1)
        navigationItems.unshift(firstPageItem)
      }

      return navigationItems.filter(item => {
        // Hide Free plan finance page for iOS app
        if (
          isPwa() &&
          item.name === this.$t('finance') &&
          ['free', 'free_v2', 'free_trial'].includes(this.tenantInfo.plan.name)
        ) {
          return false
        }

        // hide rating review page if feature is hidden
        if (item.name === this.$t('rating_review') &&
          !this.$store.getters['features/isFeatureVisible']('rating_review')) {
          return false
        }

        const permissions = this.$store.state.user.permissions

        let requiredPermissions = item.permissions

        if (requiredPermissions) {
          if (typeof requiredPermissions === 'string') {
            requiredPermissions = [requiredPermissions]
          }

          return requiredPermissions.filter(requiredPermission => permissions.includes(requiredPermission)).length
        }

        return true
      })
    },
  }
}
</script>

<style lang="scss">
.main-nav-bar {
  width: 72px;
  padding-top: var(--alert-height);
  height: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  background: var(--main-navigation-color);
  overflow: auto;
  transition: all 0.3s ease;

  // Logo
  &__logo {
    height: 40px;
    width: 40px;
    margin: 10px 0 0 0;

    // Image
    img {
      width: inherit;
      height: inherit;
      object-fit: contain;
    }
  }

  // Expand
  &__expand {
    width: 68px;
    height: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px 0 12px 0;
    cursor: pointer;
    border-radius: 3px;
    gap: 12px;

    div {
      display: flex;
    }

    &:hover {
      background-color: rgba(255, 255, 255, 0.1);
    }
  }

  // Top Menu
  &__top {
    width: 100%;
  }

  // Bottom Menu
  &__bottom {
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  // Expanded
  &.is-expanded {
    width: 84px;

    // Item
    .main-nav-bar__item {
      height: 60px;

      span {
        display: block;
        padding: 0 4px;
      }
    }
  }
}
</style>
