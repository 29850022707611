<template>

  <!-- Currency Input -->
  <div class="adm-input-wrapper" :style="{ maxWidth }">
    <div
      class="adm-input"
      :class="[
        size ? 'adm-input__size__' + size : '',
        disabled ? 'is-disabled' : '',
      ]"
    >
      <currency-input
        class="adm-currency-input"
        :placeholder="placeholder"
        :value="model"
        :disabled="disabled"
        :allow-negative="false"
        :currency="{prefix: getPricePrefix(), suffix: getPriceSuffix() }"
        :precision="showDecimals ? getCurrencyInputLocale() : 0"
        :locale="getCurrencyInputLocale()"
        :value-range="{min: minValue}"
        @input="(eventValue) => $emit('input', eventValue)"
        @change="(eventValue) => $emit('change', eventValue)"
      />
    </div>
  </div>
  <!-- /Currency Input -->

</template>

<script>
import mixinPrice from '@/mixins/common/price'

export default {
  name: 'AdmInputCurrency',

  mixins: [
    mixinPrice
  ],

  props: {
    value: {
      type: [String, Number],
      default: null
    },
    size: {
      type: String,
      default: 'default',
      validator (value) {
        return ['default', 'medium', 'small'].indexOf(value) !== -1
      }
    },
    disabled: Boolean,
    maxWidth: {
      type: String,
      default: null
    },
    placeholder: {
      type: String,
      default: ''
    },
    showDecimals: {
      type: Boolean,
      default: true,
    },
    minValue: {
      type: [String, Number],
      default: null
    },
  },

  data () {
    return {
      selfModel: ''
    }
  },

  computed: {
    model: {
      get () {
        return this.value !== undefined ? this.value : this.selfModel
      },
      set (val) {
        this.selfModel = val
      }
    }
  },
}
</script>

<style lang="scss">
.adm-currency-input {
  width: 100%;
  outline: none;
}
</style>
