<template>

  <!-- Tag -->
  <div
    class="adm-tag"
    :class="`adm-tag__${type}`"
    :style="{ color: textColor, backgroundColor, borderColor }"
  >

    <!-- Content -->
    <slot />
    <!-- /Content -->

  </div>
  <!-- /Tag -->

</template>

<script>
export default {
  name: 'AdmTag',

  props: {
    type: {
      type: String,
      default: 'default',
      validator (value) {
        return [
          'default',
          'primary',
          'positive',
          'danger',
          'danger-secondary',
          'warning',
          'success',
          'success-secondary',
          'starter',
          'scaling'
        ].indexOf(value) !== -1
      }
    },
    backgroundColor: {
      type: String,
      default: null,
      required: false,
    },
    textColor: {
      type: String,
      default: null,
      required: false,
    },
    borderColor: {
      type: String,
      default: null,
      required: false,
    },
  },
}
</script>

<style lang="scss">
// Tag
.adm-tag {
  user-select: none;
  font-weight: 500;
  border-radius: 6px;
  height: 18px;
  padding: 0 0.25rem;
  align-items: center;
  display: inline-flex;
  flex-shrink: 0;
  font-size: 12px;
  line-height: 12px;
  white-space: nowrap;

  // Default
  &__default {
    background-color: $shade-250;
    border: 1px solid $shade-300;
  }

  // Primary
  &__primary {
    background-color: var(--primary-900);
    border: 1px solid var(--primary-1000);
    color: $adm-white;
  }

  // Positive
  &__positive {
    background-color: var(--primary-400);
    border: 1px solid var(--primary-600);
    color: var(--primary-1000);
    padding: 0 .3125rem;
  }

  // Danger
  &__danger {
    background-color: $red-900;
    border: 1px solid $red-1000;
    color: $adm-white;
  }

  // Danger Secondary
  &__danger-secondary {
    background-color: $red-400;
    color: $red-1000;
    padding: 0 .3125rem;
  }

  // Warning
  &__warning {
    background-color: $yellow-900;
    border: 1px solid $yellow-1000;
  }

  // Success
  &__success {
    background-color: $green-900;
    border: 1px solid $green-1000;
    color: $adm-white;
  }

  // Success Secondary
  &__success-secondary {
    background-color: $green-500;
    border: 1px solid $green-600;
  }

  // Starter Plan
  &__starter {
    background-color: var(--primary-400);
    color: var(--primary-1000);
    padding: 1px 6px;
  }

  // Scaling Plan
  &__scaling {
    background-color: $yellow-400;
    color: $yellow-1000;
    padding: 1px 6px;

    .adm-icon:before {
      color: $yellow-1000;
    }
  }
}
</style>
