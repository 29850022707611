<template>

  <!-- Main Navigation Bar Mobile -->
  <div class="main-nav-bar-mobile">

    <!-- Menu -->
    <transition name="fade">
      <div class="main-nav-bar-mobile__wrapper">

        <!-- Menu Items -->
        <main-navigation-item-mobile
          v-for="menuItem in getFormattedMenuItems()"
          :key="menuItem.name"
          :icon="menuItem.icon"
          :label="menuItem.label"
          :route="menuItem.route"
          :route-name="menuItem.routeName"
          :on-click="menuItem.onClick"
        />
        <!-- /Menu Items -->
      </div>
    </transition>
    <!-- /Menu -->

    <adm-drawer-mobile
      v-if="isMoreMenuDisplayed"
      :title="$transWithBusinessName('{{ BUSINESS_NAME }}')"
      @close="closeMoreMenu"
    >
      <adm-drawer-item-mobile
        v-for="item in getFormattedMoreMenuItems()"
        :key="item.name"
        :action="item.action"
        :icon="getIcon(item)"
        :label="item.label"
        :route="item.route"
        :route-name="item.routeName"
        @drawer-item-clicked="(action) => { handleItemClick(action); closeMoreMenu() }"
      />
    </adm-drawer-mobile>

  </div>
  <!-- /Main Navigation Bar Mobile -->

</template>

<script>
import MainNavigationItemMobile
  from '@/views/Dashboard/MainNavigationBar/MainNavigationItemMobile/MainNavigationItemMobile'
import AdmDrawerMobile from '@/views/_components/mobile/drawerMobile/AdmDrawerMobile'
import AdmDrawerItemMobile from '@/views/_components/mobile/drawerMobile/AdmDrawerItemMobile'
import mixinMenu from '@/mixins/menu/menu'
import mixinFinanceRoute from '@/mixins/security/financeRoute'
import { isPwa } from '@/utils/isPwa'
import { mapState } from 'vuex'

export default {
  name: 'MainNavigationBarMobile',

  components: {
    AdmDrawerItemMobile,
    AdmDrawerMobile,
    MainNavigationItemMobile
  },

  mixins: [mixinMenu, mixinFinanceRoute],

  data () {
    return {
      isMoreMenuDisplayed: false,
    }
  },

  computed: {
    ...mapState({
      tenantInfo: state => state.billing.tenantInfo,
    }),
  },

  methods: {
    getFormattedMenuItems: function () {
      const menuItems = [
        {
          label: this.$t('dashboard'),
          routeName: 'dashboard',
          icon: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path class="customize-fill" d="M12 5a7 7 0 00-5.229 11.654c.469-.459 1.055-.804 1.645-1.05A8.173 8.173 0 0111.5 15c1.037 0 2.136.207 3.084.603.805.337 1.6.855 2.103 1.596A7 7 0 0012 5zm0 14a6.968 6.968 0 01-3.684-1.047c.217-.178.506-.352.872-.505A6.175 6.175 0 0111.5 17c.791 0 1.625.161 2.312.448.612.257 1.01.57 1.214.866A6.972 6.972 0 0112 19zm-9-7a9 9 0 1118 0 9 9 0 01-18 0zm11.832-2.445a1 1 0 00-1.664-1.11l-2 3a1 1 0 101.664 1.11l2-3z" fill="#005AEE"/></svg>`,
          permissions: 'read_dashboard'
        },
        {
          label: this.$t('calendar'),
          routeName: 'calendar',
          icon: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path class="customize-fill" d="M17.6 4.32h-.933v-.96c0-.576-.374-.96-.934-.96s-.933.384-.933.96v.96H9.2v-.96c0-.576-.373-.96-.933-.96s-.934.384-.934.96v.96H6.4c-1.587 0-2.8 1.248-2.8 2.88v11.52c0 1.632 1.213 2.88 2.8 2.88h11.2c1.587 0 2.8-1.248 2.8-2.88V7.2c0-1.632-1.213-2.88-2.8-2.88zm.933 5.184V18.72c0 .576-.373.96-.933.96H6.4c-.56 0-.933-.384-.933-.96V7.2c0-.576.373-.96.933-.96h.933v.96c0 .576.374.96.934.96s.933-.384.933-.96v-.96h5.6v.96c0 .576.373.96.933.96s.934-.384.934-.96v-.96h.933c.56 0 .933.384.933.96v2.304z" fill="#005AEE"/></svg>`,
          permissions: 'read_calendar'
        },
        {
          label: this.$t('appointments'),
          routeName: 'appointments',
          icon: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path class="customize-fill" d="M20 11.83a1 1 0 00-1 1v5.57a.6.6 0 01-.6.6H5.6a.6.6 0 01-.6-.6V5.6a.6.6 0 01.6-.6h9.57a1 1 0 100-2H5.6A2.61 2.61 0 003 5.6v12.8A2.61 2.61 0 005.6 21h12.8a2.61 2.61 0 002.6-2.6v-5.57a1 1 0 00-1-1z" fill="#005AEE"/><path class="customize-fill" d="M10.72 11a1 1 0 00-1.44 1.38l2.22 2.33a1 1 0 001.44.01l6.78-7a1.005 1.005 0 00-1.117-1.622c-.121.052-.231.127-.323.222l-6.05 6.26L10.72 11z" fill="#005AEE"/></svg>`,
          permissions: 'read_appointment'
        },
        {
          label: this.$t('services'),
          routeName: 'services',
          icon: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path class="customize-fill" d="M20.12 6.71l-2.83-2.83A3 3 0 0015.17 3H8.83a3 3 0 00-2.12.88L3.88 6.71A3 3 0 003 8.83V18a3 3 0 003 3h12a3 3 0 003-3V8.83a3 3 0 00-.88-2.12zm-12-1.42A1.05 1.05 0 018.83 5h6.34c.265.004.518.107.71.29L17.59 7H6.41l1.71-1.71zM18 19H6a1 1 0 01-1-1V9h14v9a1 1 0 01-1 1z" fill="#005AEE"/><path class="customize-fill" d="M15 11a1 1 0 00-1 1 2 2 0 01-4 0 1 1 0 10-2 0 4 4 0 108 0 1 1 0 00-1-1z" fill="#005AEE"/></svg>`,
          permissions: 'read_service'
        },
        {
          label: this.$t('more'),
          icon: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path class="customize-fill" d="M20.05 11H3.95a.95.95 0 00-.95.95v.1c0 .525.425.95.95.95h16.1a.95.95 0 00.95-.95v-.1a.95.95 0 00-.95-.95zM20.05 16H3.95a.95.95 0 00-.95.95v.1c0 .525.425.95.95.95h16.1a.95.95 0 00.95-.95v-.1a.95.95 0 00-.95-.95zM20.05 6H3.95a.95.95 0 00-.95.95v.1c0 .525.425.95.95.95h16.1a.95.95 0 00.95-.95v-.1a.95.95 0 00-.95-.95z" fill="#005AEE"/></svg>`,
          onClick: () => this.toggleMoreMenu()
        }
      ]

      return this.formatNavigationItems(menuItems)
    },

    getFormattedMoreMenuItems: function () {
      const moreMenuItems = [
        {
          label: this.$t('employees'),
          routeName: 'employees',
          icon: 'employee',
          permissions: ['read_employee', 'edit_own_employee_profile']
        },
        {
          label: this.$t('customers'),
          routeName: 'customers',
          icon: 'customers',
          permissions: 'read_customer'
        },
        {
          label: this.$t('locations'),
          routeName: 'locations',
          icon: 'locations',
          permissions: 'read_location'
        },
        {
          label: this.$t('finance'),
          routeName: this.getFinanceRoute(),
          icon: 'bill',
          permissions: ['read_coupon', 'read_tax', 'read_invoice_transaction', 'read_employee_commission', 'read_gift_card']
        },
        {
          label: this.$t('features_&_integrations'),
          routeName: 'features',
          icon: 'bulbtip',
          permissions: 'edit_settings'
        },
        {
          label: this.$t('rating_review'),
          routeName: 'rating-review',
          icon: 'star-outlined',
          permissions: 'edit_settings'
        },
        {
          label: this.$t('settings'),
          routeName: 'settings',
          icon: 'cog',
          permissions: ['edit_settings']
        },
        {
          label: this.$t('customize'),
          routeName: 'customize',
          icon: 'customize',
          permissions: ['edit_settings']
        }
      ]

      return this.formatNavigationItems(moreMenuItems)
    },

    formatNavigationItems (navigationItems) {
      return navigationItems.filter(item => {
        // Hide Free plan finance page for iOS app
        if (
          isPwa() &&
          item.label === this.$t('finance') &&
          ['free', 'free_v2', 'free_trial'].includes(this.tenantInfo.plan.name)
        ) {
          return false
        }

        // hide rating review page if feature is hidden
        if (item.label === this.$t('rating_review') &&
          !this.$store.getters['features/isFeatureVisible']('rating_review')) {
          return false
        }

        // Whether show More item
        if (item.label === this.$t('more') && !this.getFormattedMoreMenuItems().length) {
          return false
        }

        // Check if feature available in selected plan
        if (!this.$store.getters['features/isFeatureAvailableInPlan'](item.planFeature)) {
          return false
        }

        const permissions = this.$store.state.user.permissions

        let requiredPermissions = item.permissions

        if (requiredPermissions) {
          if (typeof requiredPermissions === 'string') {
            requiredPermissions = [requiredPermissions]
          }

          return requiredPermissions.filter(requiredPermission => permissions.includes(requiredPermission)).length
        }

        return true
      })
    },

    toggleMoreMenu () {
      this.isMoreMenuDisplayed = !this.isMoreMenuDisplayed
    },

    closeMoreMenu () {
      this.isMoreMenuDisplayed = false
    }
  }
}
</script>

<style lang="scss">
.main-nav-bar-mobile {
  &__wrapper {
    width: 100%;
    height: 66px;
    display: flex;
    position: fixed;
    align-items: center;
    justify-content: space-around;
    z-index: 10;
    bottom: 0;
    background: $adm-white;
    box-shadow: 0px -1px 1px rgba(0, 18, 79, 0.08);
  }
}
</style>
